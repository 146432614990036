@import '../../../../styles/customMediaQueries.css';

.heading {
  composes: marketplaceBodyFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 30px;
}

.obligationList {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 32px;
}

.obligationItem {
  margin-bottom: 12px;
  line-height: 24px;
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.downloadBtn {
  margin-top: 30px;
}

.goBack {
  text-align: center;
  margin-top: 30px;
  display: block;
}

.thankYouBody {
  text-align: center;
  padding: 0 36px;
  margin-top: 30px;
}
