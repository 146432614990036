@import '../../../../styles/customMediaQueries.css';

.cpContainer {
  margin-top: 46px;
}

.signupBtn {
  @media (--viewportMedium) {
    width: 290px;
  }
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.chooseAccountHeader {
  composes: marketplaceBodyFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 30px;
}

p {
  line-height: 24px;
}
